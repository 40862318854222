import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa1, fa2, fa3 } from "@fortawesome/free-solid-svg-icons";

const cardData = [
  {
    icon: fa1,
    color: "#FABB17",
    text: "İlk olarak en taze ve doğal ürünleri, üreticilerden özenle seçerek alıyoruz.",
    iconType: "fontawesome",
  },
  {
    icon: fa2,
    color: "#EC4A52",
    text: "Sonrasında aldığımız ürünleri bazı işleme süreçlerinden geçirerek, kullanıma hazır hale getiriyoruz.",
    iconType: "fontawesome",
  },
  {
    icon: fa3,
    color: "#04A45B",
    text: "Günlük olarak elde ettiğimiz üstün kaliteli hazır gıdaları siz değerli müşterilerimize tedarik ederek şeflerimizin yükünü hafifletiyoruz.",
    iconType: "fontawesome",
  },
  {
    icon: TaskAltIcon,
    color: "#1CD699",
    text: "Ürünlerimizi <b>‘Son Kullanma Tarihi’</b> etiketini bastırarak paketliyoruz.",
    iconType: "mui",
  },
  {
    icon: TaskAltIcon,
    color: "#1CD699",
    text: "<b>İhda Gıda Firması</b> olarak <b>Tarım ve Köy İşleri Bakanlığınca</b> onaylanmış üretim izin belgelerine sahibiz.",
    iconType: "mui",
  },
  {
    icon: LocalShippingIcon,
    text: "<b>Sakarya</b> ili içerisinde teslimatımız günlük olarak yapılmaktadır.",
    iconType: "mui",
  },
];

const CustomPrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <ArrowBackIosIcon sx={{ fontSize: 40, color: "#3BA1C8" }} />
  </div>
);

const CustomNextArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <ArrowForwardIosIcon sx={{ fontSize: 40, color: "#3BA1C8" }} />
  </div>
);

const About = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <StyledBox>
      <StyledTitle variant="h1">BİZ NE YAPIYORUZ?</StyledTitle>
      <SliderWrapper>
        <Slider {...settings}>
          {cardData.map((card, index) => (
            <StyledCard key={index}>
              <StyledCardContent>
                <IconWrapper>
                  {card.iconType === "fontawesome" ? (
                    <FontAwesomeIcon
                      icon={card.icon}
                      size="3x"
                      style={{ color: card.color }}
                    />
                  ) : (
                    <card.icon sx={{ color: card.color, fontSize: 60 }} />
                  )}
                </IconWrapper>
                <StyledTypography
                  dangerouslySetInnerHTML={{ __html: card.text }}
                />
              </StyledCardContent>
            </StyledCard>
          ))}
        </Slider>
      </SliderWrapper>
    </StyledBox>
  );
};

export default About;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 50px;
  justify-content: center;
  height: 100vh;
`;

const StyledTitle = styled(Typography)`
  font-size: 50px;
  font-weight: bold;
`;

const SliderWrapper = styled("div")`
  width: 80%;
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .slick-arrow.slick-next:before {
    content: "";
  }
  .slick-arrow.slick-prev:before {
    content: "";
  }
`;

const StyledCard = styled(Card)`
  width: 300px;
  height: 350px;
  box-shadow: none;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;
`;

const IconWrapper = styled("div")`
  position: absolute;
  top: 30px;
  left: 40px;
`;

const StyledTypography = styled(Typography)`
  padding: 0 20px;
  text-align: left;
  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;
