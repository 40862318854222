import HomePage from "./pages/HomePage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import HomePageMobile from "./pages/HomePageMobile";

function App() {
  return (
    <>
      <HomePage />
      <HomePageMobile />
    </>
  );
}

export default App;
