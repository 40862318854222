import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import Slider from "react-slick";
import Potato1 from "../images/potato1.jpg";
import Potato2 from "../images/potato2.jpg";
import Potato3 from "../images/potato3.jpg";
import Potato4 from "../images/potato4.jpg";
import Potato5 from "../images/potato5.jpg";
import Potato6 from "../images/potato6.jpg";
import Potato7 from "../images/potato7.jpg";
import Potato8 from "../images/potato8.jpg";
import Potato9 from "../images/potato9.jpg";
import Onion from "../images/onion2.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const products = [
  "Soyulmuş Soğan",
  "Soyulmuş Patates",
  "Parmak Dilim Patates",
  "Kibrit Dilim Patates",
  "Yemeklik Küp Patates",
  "Garnitürlük Patates",
  "Halka Dilim Patates",
  "Elma&Ayva Dilim Patates",
  "Musakkalık Patates",
  "Bütün Patates",
  "Haşlama Patates",
];

const photos = [
  Potato1,
  Potato2,
  Potato3,
  Potato4,
  Potato5,
  Potato6,
  Potato7,
  Potato8,
  Potato9,
  Onion,
];

const CustomPrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <ArrowBackIosIcon sx={{ fontSize: 20, color: "#3BA1C8" }} />
  </div>
);

const CustomNextArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <ArrowForwardIosIcon sx={{ fontSize: 20, color: "#3BA1C8" }} />
  </div>
);

const ProductsMobile = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <StyledBox>
      <div className="flex flex-col w-4/5 gap-2">
        <StyledTitle variant="h1">ÜRÜNLERİMİZ</StyledTitle>
        <StyledDescription>
          Kızartma garantili soyulmuş ve vakumlanmış ürün çeşitlerimiz.
        </StyledDescription>
        <div>
          {products.map((product, index) => (
            <div className="flex items-end gap-1">
              <IconContainer>
                <DoneIcon sx={{ color: "#269577", fontSize: 20 }} />
              </IconContainer>
              <StyledProduct key={index}>{product}</StyledProduct>
            </div>
          ))}
        </div>
      </div>
      <StyledSlider {...settings}>
        {photos.map((photo, index) => (
          <div key={index}>
            <Photo src={photo} alt={`Product ${index + 1}`} />
          </div>
        ))}
      </StyledSlider>
    </StyledBox>
  );
};

export default ProductsMobile;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 130px;
  padding-bottom: 80px;
`;

const StyledTitle = styled(Typography)`
  font-size: 25px;
  font-weight: bold;
  color: #269577;
`;

const StyledDescription = styled(Typography)`
  font-size: 13px;
`;

const StyledProduct = styled(Typography)`
  font-size: 12px;
  border-bottom: 2px solid #dbb217;
  padding: 5px 0;
  width: 80%;
  padding-left: 10px;
`;

const IconContainer = styled(Box)`
  border: 2px solid #dbb217;
  padding: 10px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const StyledSlider = styled(Slider)`
  width: 80%;
  .slick-slide > div {
    display: flex;
    justify-content: center;
    padding: 0 5px;
  }
  .slick-arrow.slick-next:before {
    content: "";
  }
  .slick-arrow.slick-prev:before {
    content: "";
  }
`;

const Photo = styled("img")`
  width: 100%;
  object-fit: cover;
  border-radius: 20%;
  aspect-ratio: 1 / 1;
`;
