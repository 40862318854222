import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const contactDetails = [
  {
    icon: PhoneIcon,
    title: "İletişim Numarası",
    description: "0554 364 15 75",
    link: "tel:05543641575",
  },
  {
    icon: EmailIcon,
    title: "Email",
    description: "ihdagida@gmail.com",
    link: "mailto:ihdagida@gmail.com",
  },
  {
    icon: InstagramIcon,
    title: "Instagram",
    description: "ihdagida",
    link: "https://www.instagram.com/ihdagida",
  },
  {
    icon: WhatsAppIcon,
    title: "WhatsApp",
    description: "0554 364 15 75",
    link: "https://wa.me/905543641575",
  },
];

const Contact = () => {
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="flex w-full pt-40">
      <div
        className="flex w-full items-center justify-around pr-20"
        style={{ background: "#FEBD02", height: "150px" }}
      >
        {contactDetails.map((detail, index) => (
          <div
            className="flex items-center gap-5"
            key={index}
            onClick={() => handleLinkClick(detail.link)}
            style={{ cursor: "pointer" }}
          >
            <detail.icon sx={{ color: "white", fontSize: 40 }} />
            <div>
              <StyledTitle>{detail.title}</StyledTitle>
              <StyledDescription>{detail.description}</StyledDescription>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;

const StyledTitle = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

const StyledDescription = styled(Typography)`
  font-size: 18px;
  @media (max-width: 1000px) {
    font-size: 13px;
  }
`;
