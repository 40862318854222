import React from "react";
import NavBar from "../components/NavBar";
import Home from "../components/Home";
import About from "../components/About";
import Products from "../components/Products";
import Contact from "../components/Contact";
import ScrollToTop from "../components/ScrollToTop";

const HomePage = () => {
  return (
    <div
      className="w-full xs:hidden sm:hidden"
      style={{ background: "#FFF9ED" }}
    >
      <NavBar />
      <div id="home" className="h-screen">
        <Home />
      </div>
      <div id="about" className="h-screen">
        <About />
      </div>
      <div id="products" className="h-screen">
        <Products />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <ScrollToTop />
    </div>
  );
};

export default HomePage;
