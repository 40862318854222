import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import mrPatato from "../images/mrPatato.png";

const NavBarMobile = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className="flex flex-col w-screen fixed top-0 left-0 z-50"
      style={{ background: "#FFF9ED", height: "auto" }}
    >
      <div className="flex items-center justify-center">
        <img src={mrPatato} alt="mrPatato" style={{ width: "80px" }}></img>
        <StyledTitle onClick={() => scrollToSection("homeMobile")}>
          İHDA GIDA
        </StyledTitle>
      </div>
      <div className="flex items-center justify-center gap-10 pb-2">
        <StyledText onClick={() => scrollToSection("aboutMobile")}>
          Hakkımızda
        </StyledText>
        <StyledText onClick={() => scrollToSection("productsMobile")}>
          Ürünlerimiz
        </StyledText>
        <StyledText onClick={() => scrollToSection("contactMobile")}>
          İletişim
        </StyledText>
      </div>
    </div>
  );
};

export default NavBarMobile;

const StyledTitle = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  color: #ff5712;
  cursor: pointer;
`;

const StyledText = styled(Typography)`
  font-size: 15px;
  font-family: Glacial Indifference;
  font-weight: bold;
  align-content: center;
  cursor: pointer;
  &:hover {
    color: #ff5712;
  }
`;
