import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import Potato1 from "../images/potato1.jpg";
import Potato2 from "../images/potato2.jpg";
import Potato3 from "../images/potato3.jpg";
import Potato4 from "../images/potato4.jpg";
import Potato5 from "../images/potato5.jpg";
import Potato6 from "../images/potato6.jpg";
import Potato7 from "../images/potato7.jpg";
import Potato8 from "../images/potato8.jpg";
import Potato9 from "../images/potato9.jpg";
import Onion from "../images/onion2.jpg";

const products = [
  "Soyulmuş Soğan",
  "Soyulmuş Patates",
  "Parmak Dilim Patates",
  "Kibrit Dilim Patates",
  "Yemeklik Küp Patates",
  "Garnitürlük Patates",
  "Halka Dilim Patates",
  "Elma&Ayva Dilim Patates",
  "Musakkalık Patates",
  "Bütün Patates",
  "Haşlama Patates",
];

const photos = [
  Potato1,
  Potato2,
  Potato3,
  Potato4,
  Potato5,
  Potato6,
  Potato7,
  Potato8,
  Potato9,
  Onion,
];

const Products = () => {
  return (
    <StyledBox>
      <div className="w-5/12 pl-32">
        <StyledTitle variant="h1">ÜRÜNLERİMİZ</StyledTitle>
        <StyledDescription>
          Kızartma garantili soyulmuş ve vakumlanmış ürün çeşitlerimiz.
        </StyledDescription>
        {products.map((product, index) => (
          <div className="flex items-end gap-3">
            <IconContainer>
              <DoneIcon sx={{ color: "#269577", fontSize: 30 }} />
            </IconContainer>
            <StyledProduct key={index}>{product}</StyledProduct>
          </div>
        ))}
      </div>
      <div className="w-7/12 pl-20">
        <PhotoGrid>
          <PhotoColumn>
            {photos.slice(0, 3).map((photo, index) => (
              <Photo key={index} src={photo} />
            ))}
          </PhotoColumn>
          <PhotoColumn>
            {photos.slice(3, 7).map((photo, index) => (
              <Photo key={index} src={photo} />
            ))}
          </PhotoColumn>
          <PhotoColumn>
            {photos.slice(7, 10).map((photo, index) => (
              <Photo key={index} src={photo} />
            ))}
          </PhotoColumn>
        </PhotoGrid>
      </div>
    </StyledBox>
  );
};

export default Products;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding-top: 50px;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const StyledTitle = styled(Typography)`
  font-size: 50px;
  font-weight: bold;
  color: #269577;
  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;

const StyledDescription = styled(Typography)`
  font-size: 20px;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const StyledProduct = styled(Typography)`
  font-size: 18px;
  border-bottom: 2px solid #dbb217;
  padding: 5px 0;
  width: 70%;
  padding-left: 10px;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const IconContainer = styled(Box)`
  border: 2px solid #dbb217;
  padding: 10px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const PhotoGrid = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 90%;
`;

const PhotoColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(33.33% - 1px);
`;

const Photo = styled("img")`
  width: 80%;
  border-radius: 20%;
`;
