import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import mrPatato from "../images/mrPatato.png";

const NavBar = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className="flex w-screen fixed top-0 left-0 z-50"
      style={{ background: "#FFF9ED", height: "auto" }}
    >
      <div className="flex items-center justify-center w-2/5">
        <img src={mrPatato} alt="mrPatato" style={{ width: "100px" }}></img>
        <StyledTitle onClick={() => scrollToSection("home")}>
          İHDA GIDA
        </StyledTitle>
      </div>
      <div className="flex gap-20 w-3/5">
        <StyledText onClick={() => scrollToSection("about")}>
          Hakkımızda
        </StyledText>
        <StyledText onClick={() => scrollToSection("products")}>
          Ürünlerimiz
        </StyledText>
        <StyledText onClick={() => scrollToSection("contact")}>
          İletişim
        </StyledText>
      </div>
    </div>
  );
};

export default NavBar;

const StyledTitle = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  color: #ff5712;
  cursor: pointer;
`;

const StyledText = styled(Typography)`
  font-size: 18px;
  font-family: Glacial Indifference;
  font-weight: bold;
  align-content: center;
  cursor: pointer;
  &:hover {
    color: #ff5712;
  }
`;
