import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Potato from "../images/potato.png";
import Potatoes from "../images/potatoes.png";
import Ptt from "../images/ptt.jpg";
import Ptt2 from "../images/ptt2.jpg";
import Potato1 from "../images/potato1.jpg";
import Onion from "../images/onion.jpg";

const images = [
  { src: Ptt, alt: "potato", style: { width: "18%", borderRadius: "50%" } },
  {
    src: Ptt2,
    alt: "potato",
    style: { width: "35%", borderRadius: "50%", border: "5px solid #FF5712" },
  },
  { src: Potato1, alt: "potato", style: { width: "18%", borderRadius: "50%" } },
  { src: Onion, alt: "potato", style: { width: "18%", borderRadius: "50%" } },
];

const Home = () => {
  return (
    <StyledBox>
      <div className="flex justify-center gap-5">
        <StyledTitle variant="h1">Mr. Patato</StyledTitle>
        <img src={Potato} alt="potato" style={{ width: "12%" }}></img>
      </div>
      <div className="flex justify-center items-center gap-10">
        <img src={Potatoes} alt="potato" style={{ width: "12%" }}></img>
        <StyledText>GÜNLÜK ÜRETİM, GÜNLÜK SEVKİYAT</StyledText>
      </div>
      <div
        className="flex items-center w-10/12 h-2/5 rounded-3xl"
        style={{ backgroundColor: "#febd02" }}
      >
        <div className="w-1/4 pl-10 pt-5">
          <StyledDescription>
            GÜNLÜK ÜRETİLEN VAKUMLANMIŞ TAZE PATATES VE SOĞANLAR
          </StyledDescription>
          <StyledArrow>→</StyledArrow>
        </div>
        <div className="flex w-3/4 justify-center items-center gap-3">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
              style={image.style}
            />
          ))}
        </div>
      </div>
    </StyledBox>
  );
};

export default Home;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 50px;
  justify-content: center;
  height: 100vh;
`;

const StyledTitle = styled(Typography)`
  font-size: 100px;
  font-family: Garet;
  font-weight: bold;
  color: #04ab5b;
  @media (max-width: 1000px) {
    font-size: 60px;
  }
`;

const StyledText = styled(Typography)`
  font-size: 30px;
  font-family: Arima Madurai;
  color: #807979;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

const StyledDescription = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const StyledArrow = styled("div")`
  font-size: 60px;
  animation: moveArrowRight 1s infinite alternate;
  margin-left: 20px;

  @keyframes moveArrowRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(20px);
    }
  }
`;
