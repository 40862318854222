import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Potato from "../images/potato.png";
import Potatoes from "../images/potatoes.png";
import Ptt from "../images/ptt.jpg";
import Ptt2 from "../images/ptt2.jpg";
import Potato1 from "../images/potato1.jpg";
import Onion from "../images/onion.jpg";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const images = [
  { src: Ptt2, alt: "potato" },
  { src: Potato1, alt: "potato" },
  { src: Ptt, alt: "potato" },
  { src: Onion, alt: "potato" },
];

const CustomPrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <ArrowBackIosIcon sx={{ fontSize: 20, color: "#3BA1C8" }} />
  </div>
);

const CustomNextArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <ArrowForwardIosIcon sx={{ fontSize: 20, color: "#3BA1C8" }} />
  </div>
);

const HomeMobile = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <StyledBox>
      <div className="flex justify-center gap-2">
        <StyledTitle variant="h1">Mr. Patato</StyledTitle>
        <img src={Potato} alt="potato" style={{ width: "14%" }}></img>
      </div>
      <div className="flex justify-center items-center gap-4">
        <img src={Potatoes} alt="potato" style={{ width: "14%" }}></img>
        <div>
          <StyledText style={{ paddingRight: "80px" }}>
            GÜNLÜK ÜRETİM,
          </StyledText>
          <StyledText style={{ paddingLeft: "80px" }}>
            GÜNLÜK SEVKİYAT
          </StyledText>
        </div>
      </div>
      <div className="flex flex-col w-4/5 text-center pt-5">
        <StyledDescription>
          GÜNLÜK ÜRETİLEN VAKUMLANMIŞ
          <br />
          TAZE PATATES VE SOĞANLAR
        </StyledDescription>
        <StyledArrow>↓</StyledArrow>
      </div>
      <div
        className="flex flex-col w-4/5 justify-center items-center rounded-3xl p-5 gap-2"
        style={{ backgroundColor: "#febd02" }}
      >
        <StyledSlider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <Photo src={image.src} alt={image.alt} />
            </div>
          ))}
        </StyledSlider>
      </div>
    </StyledBox>
  );
};

export default HomeMobile;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 160px;
`;

const StyledTitle = styled(Typography)`
  font-size: 40px;
  font-family: Garet;
  font-weight: bold;
  color: #04ab5b;
`;

const StyledText = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  font-family: Arima Madurai;
  color: #807979;
`;

const StyledDescription = styled(Typography)`
  font-size: 13px;
  padding: 0 20px;
`;

const StyledArrow = styled("div")`
  font-size: 30px;
  animation: moveArrowDown 1s infinite alternate;

  @keyframes moveArrowDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(20px);
    }
  }
`;

const StyledSlider = styled(Slider)`
  width: 60%;
  .slick-slide > div {
    display: flex;
    justify-content: center;
    padding: 0 5px;
  }
  .slick-arrow.slick-next:before {
    content: "";
  }
  .slick-arrow.slick-prev:before {
    content: "";
  }
`;

const Photo = styled("img")`
  width: 100%;
  border-radius: 50%;
  border: 3px solid #ff5712;
`;
