import React from "react";
import AboutMobile from "../components/AboutMobile";
import ContactMobile from "../components/ContactMobile";
import HomeMobile from "../components/HomeMobile";
import NavBarMobile from "../components/NavBarMobile";
import ProductsMobile from "../components/ProductsMobile";
import ScrollToTopMobile from "../components/ScrollToTopMobile";

const HomePageMobile = () => {
  return (
    <div
      className="w-full md:hidden lg:hidden xl:hidden"
      style={{ background: "#FFF9ED" }}
    >
      <NavBarMobile />
      <div id="homeMobile">
        <HomeMobile />
      </div>
      <div id="aboutMobile">
        <AboutMobile />
      </div>
      <div id="productsMobile">
        <ProductsMobile />
      </div>
      <div id="contactMobile">
        <ContactMobile />
      </div>
      <ScrollToTopMobile />
    </div>
  );
};

export default HomePageMobile;
