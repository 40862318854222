import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const contactDetails = [
  {
    icon: PhoneIcon,
    title: "İletişim Numarası",
    description: "0554 364 15 75",
    link: "tel:05543641575",
  },
  {
    icon: EmailIcon,
    title: "Email",
    description: "ihdagida@gmail.com",
    link: "mailto:ihdagida@gmail.com",
  },
  {
    icon: InstagramIcon,
    title: "Instagram",
    description: "ihdagida",
    link: "https://www.instagram.com/ihdagida",
  },
  {
    icon: WhatsAppIcon,
    title: "WhatsApp",
    description: "0554 364 15 75",
    link: "https://wa.me/905543641575",
  },
];

const ContactMobile = () => {
  const handleLinkClick = (url) => {
    if (url.startsWith("tel:")) {
      window.location.href = url;
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <div
      className="grid grid-cols-2 gap-4"
      style={{ background: "#FEBD02", padding: "20px" }}
    >
      {contactDetails.map((detail, index) => (
        <div
          className="flex items-center gap-2"
          key={index}
          onClick={() => handleLinkClick(detail.link)}
          style={{ cursor: "pointer" }}
        >
          <detail.icon sx={{ color: "white", fontSize: 25 }} />
          <div>
            <StyledTitle>{detail.title}</StyledTitle>
            <StyledDescription>{detail.description}</StyledDescription>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactMobile;

const StyledTitle = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

const StyledDescription = styled(Typography)`
  font-size: 10px;
`;
